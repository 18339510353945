import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Home from './components/Home'
import Header from './components/common/header'
import ControlPanel from './components/ControlPanel'
import Login from './components/Login'
import Registration from './components/Registration'
import Users from './components/Users'

export default class App extends Component {
    componentWillMount() {
        document.body.style.backgroundColor = "white";
    }

    render() {
        return (
            <div className="mainDiv">
                <Header></Header>
                <Router>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/home' component={Home} />
                        <Route exact path='/history' component={ControlPanel} />
                        <Route exact path='/users' component={Users} />
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/registration' component={Registration} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
