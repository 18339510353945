import React, { useEffect } from 'react'
import './style.css'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import { Alert } from "react-bootstrap";
import { connect } from 'react-redux';

function _Login(props) {
    useEffect(() => {

    }, [])

    let dispatch = useDispatch();
    let authUserName = props.auth.authUserName;
    let authPwd = props.auth.authPwd;
    let isSuccessAuth = props.auth.isSuccessAuth;
    let isLoading = props.isAuthLoading;

    const handleChange = (e) => {
        if (e.currentTarget.id === "authUserName") {
            dispatch(Actions.setAuthUserName(e.currentTarget.value));
        } else if (e.currentTarget.id === "authPwd") {
            dispatch(Actions.setAuthPwd(e.currentTarget.value));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        var authUser = {
            userName: authUserName,
            pwd: authPwd
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...authUser })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/SignIn", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsSuccessAuth(true));
                        dispatch(Actions.setCurrentUser({ userName: authUserName, pwd: "password", isAdmin: result.data.isAdmin }));
                        window.localStorage.setItem("token", result.data.token);
                        dispatch(Actions.setIsLoading(false));
                        props.history.push("/home");
                    } else {
                        dispatch(Actions.setIsSuccessAuth(false));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setIsSuccessAuth(false));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const emailInput = <input type="email" className="inputSize form-control" id="authUserName"
        placeholder="Enter email" value={authUserName} onChange={handleChange} />

    const passwordInput = <input type="password" className="inputSize form-control"
        id="authPwd" placeholder="Enter password" value={authPwd} onChange={handleChange} />

    const _Loader = () => {
        if (isLoading) {
            return (
                <div className="loaderRow">
                    <div className="loaderColumn">
                        <div id="homeLoader" className="spinner-border loader" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const AlertDismissible = () => {
        if (!isSuccessAuth) {
            return (
                <Alert variant="danger" onClose={() => dispatch(Actions.setIsSuccessAuth(true))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        Enter correct email and password.
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            <_Loader />
            <form className="loginForm" onSubmit={handleSubmit}>
                <h1 className="header">Log In</h1>
                <div className="form-group inputSizeDiv">
                    {emailInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordInput}
                </div>
                <button type="submit" className="submitButton btn btn-primary">Submit</button>
                <div className="additionalRow">
                    <a href="/registration">Haven't got account?</a>
                </div>
            </form>
            <AlertDismissible />
        </div>
    )
}

const Login = connect(state => ({
    ...state
}))(_Login);

export default withRouter(Login)