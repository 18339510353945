import React from 'react'
import './style.css'
import { withRouter } from 'react-router-dom'
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import { connect } from 'react-redux';

function _Registration(props) {
    let dispatch = useDispatch();
    let authUserName = props.auth.authUserName;
    let authPwd = props.auth.authPwd;
    let isSuccessAuth = props.auth.isSuccessAuth;
    let authConfirmPwd = props.auth.authConfirmPwd;
    let isLoading = props.isAuthLoading;

    const handleChange = (e) => {
        if (e.currentTarget.id === "authUserName") {
            dispatch(Actions.setAuthUserName(e.currentTarget.value));
        } else if (e.currentTarget.id === "authPwd") {
            dispatch(Actions.setAuthPwd(e.currentTarget.value));
        } else if(e.currentTarget.id === "authConfirmPwd"){
            dispatch(Actions.setAuthConfirmPwd(e.currentTarget.value));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (authPwd !== authConfirmPwd) {
            dispatch(Actions.setIsSuccessAuth(false));
            return;
        }

        var authUser = {
            userName: authUserName,
            pwd: authPwd
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...authUser })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/SignOn", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsSuccessAuth(true));
                        dispatch(Actions.setCurrentUser({ userName: authUserName, pwd: "password", isAdmin: false }));
                        dispatch(Actions.setIsAdmin(false));
                        window.localStorage.setItem("token", result.data.token);
                        dispatch(Actions.setIsLoading(false));
                        props.history.push("/home");
                    }
                    else {
                        dispatch(Actions.setIsSuccessAuth(false));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setIsSuccessAuth(false));
                    dispatch(Actions.setIsLoading(false));
                }
            );
    }

    const _Loader = () => {
        if (isLoading) {
            return (
                <div id="homeLoader" className="spinner-border loader" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const AlertDismissible = () => {
        if (!isSuccessAuth) {
            return (
                <Alert variant="danger" onClose={() => dispatch(Actions.setIsSuccessAuth(true))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        Enter correct email and password.
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const emailInput = <input type="email" className="inputSize form-control" id="authUserName"
        placeholder="Enter email" value={authUserName} onChange={handleChange} />

    const passwordInput = <input type="password" className="inputSize form-control" id="authPwd"
        placeholder="Enter password" value={authPwd} onChange={handleChange} />

    const passwordConfirmInput = <input type="password" className="inputSize form-control" id="authConfirmPwd"
        placeholder="Confirm password" value={authConfirmPwd} onChange={handleChange} />

    return (
        <div>
            <_Loader />
            <form className="registrationForm" onSubmit={handleSubmit}>
                <h1 className="header">Registration</h1>
                <div className="form-group inputSizeDiv">
                    {emailInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordConfirmInput}
                </div>
                <button type="submit" className="submitButton btn btn-primary">Registrate</button>
                <div className="additionalRow">
                    <a href="/login">Have account?</a>
                </div>
                <div className="resultMainDiv">
                    <span className="alert alert-danger resultSpan invicible" id="errorLogin" role="alert">
                        Error!
                    </span>
                </div>
            </form>
            <AlertDismissible />
        </div>
    )
}

const Registration = connect(state => ({
    ...state
}))(_Registration);

export default withRouter(Registration)