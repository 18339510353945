import 'bootstrap/dist/css/bootstrap.css';
import './style.css'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { rootReducer } from './store/reducers'

const rootElement = document.getElementById('root');

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement);
