import React from 'react'
import SimsLogo from "../../../assets/img/sims-header-logo.png"
import './style.css';

function Header() {
    return (
        <div className="headerLogoDiv">
            <img className="headerLogoImg" src={SimsLogo}></img>
        </div>
    )
}

export default Header