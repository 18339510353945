import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import './style.css'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import NavBar from '../common/navbar/index'
import { Table, Tooltip, OverlayTrigger, Button, ButtonGroup, Modal, Form, Alert, Pagination } from "react-bootstrap";
import { connect } from 'react-redux';
import sortDownImage from '../../assets/img/sort_down.png';
import sortUpImage from '../../assets/img/sort_up.png';

function _Users(props) {
    const WINDOW_WIDTH = document.documentElement.clientWidth;
    const FILE_NAME_LENGTH = WINDOW_WIDTH >= 1100 ? 21 : 13;
    let dispatch = useDispatch();
    let users = props.users;
    let showEditWindow = props.editUser.showEditWindow;
    let showCreateWindow = props.createUser.showCreateWindow;
    let selectedUser = props.editUser.selectedUser;
    let userForEdit = props.editUser.newUser;
    let isSuccessEdit = props.editUser.isSuccessEdit;
    let isSuccessCreation = props.createUser.isSuccessCreation;
    let action = props.actions.action;
    let param = props.actions.param;
    let activePageNumber = props.pagination.activePageNumber;
    let pages = props.pagination.pages;
    let showRemoveUserErrorWindow = props.controlPanelComponent.showRemoveUserErrorWindow;
    let showReactivateLinkErrorWindow = props.controlPanelComponent.showReactivateLinkErrorWindow;
    let isLoading = props.isLoading;
    let userForCreation = props.createUser.newUser;
    let userForAction = props.userForAction;
    let showActionErrorWindow = props.showActionErrorWindow;
    let currentUser = props.currentUser;
    let userNameSortType = props.usersComponent.userNameSortType;
    let userTyprSortType = props.usersComponent.userTyprSortType;
    let sortField = props.usersComponent.sortField;

    const handleWindowBeforeUnload = e => {
        if (window.performance && performance.navigation.type != 1) {
            window.localStorage.removeItem("token");
        }
    };

    const USER_SORT_TYPES_ENUM = {
        userName: 0,
        userType: 4
    }

    window.addEventListener('beforeunload', handleWindowBeforeUnload);

    const itemsPerPage = 10;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const setActivePageNumber = (e) => {
        dispatch(Actions.setActivePageNumber(+e.currentTarget.id));
    }

    const _Loader = () => {
        if (isLoading) {
            return (
                <div className="rowLoader">
                    <div className="columnLoader">
                        <div id="homeLoader" className="spinner-border loader" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const handleAction = () => {
        if (action) {
            action(param);
        }
    };

    const getAllUsers = async (token) => {
        var sortType = sortField == 0 ? userNameSortType : userTyprSortType;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, sortField, sortType })
        }
        dispatch(Actions.setIsLoading(true));
        await fetch("/GetAllUsers", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setUsers(result.data));
                        let pagesCount = Math.ceil(result.data.length / itemsPerPage);
                        if (pagesCount === 0) {
                            pagesCount++;
                        }
                        let _pages = [];
                        for (let i = 1; i <= pagesCount; i++) {
                            _pages.push(i);
                        }
                        dispatch(Actions.setPages(_pages));
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setUsers([]));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setUsers([]));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    useEffect(() => {
        var token = window.localStorage.getItem("token");
        getAllUsers(token);
    }, [])

    const removeUser = (e) => {
        var userName = e;
        var token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName, token })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/RemoveUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setShow(false);
                    if (result.status === "Success") {
                        getAllUsers(token);
                        dispatch(Actions.setIsLoading(false));
                        if (result.data.isLogOut) {
                            dispatch(Actions.setIsLoading(false));
                            props.history.push("/login");
                        }
                    } else {
                        dispatch(Actions.setShowRemoveUserErrorWindow(true));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setShowRemoveUserErrorWindow(true));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    const onRemoveUser = (e) => {
        if (!userForAction) {
            dispatch(Actions.setShowActionErrorWindow(true));
            return;
        }
        dispatch(Actions.setAction(removeUser));
        dispatch(Actions.setParam(userForAction));
        setShow(true);
    }

    const Confirmation = () => {
        return (
            <>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={handleClose}>
                            No
                    </Button>
                        <Button variant="primary" onClick={handleAction}>
                            Yes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const _handleClose = () => {
        dispatch(Actions.setShowEditWindow(false));
    }

    const _handleCreationClose = () => {
        dispatch(Actions.setShowCreateWindow(false));
    }

    const onEditUser = (e) => {
        var userName = userForAction;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userName })
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/GetUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setSelectedUser(result.data));
                        dispatch(Actions.setSelectedForEditUser(result.data));
                        dispatch(Actions.setIsLoading(false));
                        dispatch(Actions.setShowEditWindow(true));
                    } else {
                        dispatch(Actions.setSelectedUser({ userName: "", pwd: "password", isAdmin: false }));
                        dispatch(Actions.setSelectedForEditUser({ userName: "", pwd: "password", isAdmin: false }));
                        dispatch(Actions.setIsLoading(false));
                        dispatch(Actions.setShowActionErrorWindow(true));
                    }
                },
                (error) => {
                    dispatch(Actions.setSelectedUser({ userName: "", pwd: "password", isAdmin: false }));
                    dispatch(Actions.setSelectedForEditUser({ userName: "", pwd: "password", isAdmin: false }));
                    dispatch(Actions.setIsLoading(false));
                    dispatch(Actions.setShowActionErrorWindow(true));
                }
            )
    }

    const AlertDismissible = (params) => {
        if (params.show) {
            return (
                <Alert variant="danger" onClose={() => dispatch(params.action(!params.show))} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                        {params.message}
                    </p>
                </Alert>
            );
        } else {
            return null;
        }
    }

    const handleChange = (e) => {
        if (e.currentTarget.id === "newUserName") {
            dispatch(Actions.setEditUserName(e.currentTarget.value));
        } else if (e.currentTarget.id === "newPwd") {
            dispatch(Actions.setEditPwd(e.currentTarget.value));
        } else if (e.currentTarget.id === "newIsAdmin") {
            dispatch(Actions.setEditIsAdmin(e.currentTarget.checked));
        } else if (e.currentTarget.id === "createUserName") {
            dispatch(Actions.setCreateUserName(e.currentTarget.value));
        } else if (e.currentTarget.id === "createPwd") {
            dispatch(Actions.setCreatePwd(e.currentTarget.value));
        } else if (e.currentTarget.id === "createIsAdmin") {
            dispatch(Actions.setCreateIsAdmin(e.currentTarget.checked));
        }
    }

    const onSaveUser = () => {
        var token = window.localStorage.getItem("token");
        var model = {
            oldUserName: selectedUser.userName,
            newUserName: userForEdit.userName,
            newPwd: userForEdit.pwd,
            IsAdmin: userForEdit.isAdmin,
            token
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/EditUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsSuccessEdit(true));
                        window.localStorage.setItem("token", result.data.token);
                        _handleClose();
                        getAllUsers(token);
                        dispatch(Actions.setIsLoading(false));
                        if (result.data.isLogOut) {
                            props.history.push("/login");
                        }
                    } else {
                        dispatch(Actions.setIsSuccessEdit(false));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setIsSuccessEdit(false));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    const onSaveCreationUser = () => {
        var token = window.localStorage.getItem("token");
        var model = {
            userName: userForCreation.userName,
            pwd: userForCreation.pwd,
            IsAdmin: userForCreation.isAdmin
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        }
        dispatch(Actions.setIsLoading(true));
        fetch("/CreateUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setIsSuccessCreation(true));
                        _handleCreationClose();
                        getAllUsers(token);
                        dispatch(Actions.setIsLoading(false));
                    } else {
                        dispatch(Actions.setIsSuccessCreation(false));
                        dispatch(Actions.setIsLoading(false));
                    }
                },
                (error) => {
                    dispatch(Actions.setIsSuccessCreation(false));
                    dispatch(Actions.setIsLoading(false));
                }
            )
    }

    const editWindow = (
        <Modal show={showEditWindow} onHide={_handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="userNameFormGroup">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" id="newUserName" value={userForEdit.userName} onChange={handleChange} placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group controlId="pwdFormGroup">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" id="newPwd" value={userForEdit.pwd} onChange={handleChange} placeholder="Password" />
                    </Form.Group>

                    <Form.Group controlId="isAdminFormGroup">
                        <Form.Check type="checkbox" id="newIsAdmin" label="Is Admin" onChange={handleChange} checked={userForEdit.isAdmin} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={_handleClose}>
                    Close
          </Button>
                <Button variant="primary" onClick={onSaveUser}>
                    Save Changes
          </Button>
            </Modal.Footer>
            <AlertDismissible message="Error! User wasn't edited." show={!isSuccessEdit} action={Actions.setIsSuccessEdit} />
        </Modal>
    )

    const creationWindow = (
        <Modal show={showCreateWindow} onHide={_handleCreationClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="newUserNameFormGroup">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" id="createUserName" value={userForCreation.userName} onChange={handleChange} placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group controlId="newPwdFormGroup">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" id="createPwd" value={userForCreation.pwd} onChange={handleChange} placeholder="Password" />
                    </Form.Group>

                    <Form.Group controlId="newIsAdminFormGroup">
                        <Form.Check type="checkbox" id="createIsAdmin" label="Is Admin" onChange={handleChange} checked={userForCreation.isAdmin} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={_handleCreationClose}>
                    Close
          </Button>
                <Button variant="primary" onClick={onSaveCreationUser}>
                    Save Changes
          </Button>
            </Modal.Footer>
            <AlertDismissible message="Error! User wasn't created." show={!isSuccessCreation} action={Actions.setIsSuccessCreation} />
        </Modal>
    );

    const onSetUserForAction = (e) => {
        if (userForAction != e.currentTarget.id) {
            dispatch(Actions.setUserForAction(e.currentTarget.id));
        } else {
            dispatch(Actions.setUserForAction(""));
        }
    }

    const usersTableBody = users && users.slice((activePageNumber - 1) * itemsPerPage, itemsPerPage * activePageNumber).map((e, i) => {
        return (
            <>
                <tr className="rowWidth" key={i}>
                    <td className="smallColumnWidth">
                        <div className="verticalCenter fullHeight">
                            <div className="checkboxIput">
                                <input id={e.userName} type="checkbox" className="checkbox" checked={userForAction === e.userName} onChange={onSetUserForAction} />
                            </div>
                        </div>
                    </td>
                    <td className="midleColumnWidth"><span>{e.userName}</span></td>
                    {e.isAdmin ? <td className="midleColumnWidth">Administrator</td> : <td className="midleColumnWidth">User</td>}
                </tr>

            </>
        )
    })

    const _pages = pages.map((e, i) => {
        return (
            <Pagination.Item key={e} active={+e === activePageNumber} id={e} onClick={setActivePageNumber}>
                {e}
            </Pagination.Item>
        )
    })

    const paginationBasic = (
        <Pagination>{_pages}</Pagination>
    );

    const onOpenCreationWindow = () => {
        dispatch(Actions.setShowCreateWindow(true));
    }

    const sort = (e) => {
        var _sortField = USER_SORT_TYPES_ENUM[e.currentTarget.id];
        dispatch(Actions.setChangeUsersSortField(_sortField));
        if (_sortField == 0) {
            dispatch(Actions.setChangeUserNameSortType());
            userNameSortType = userNameSortType == "asc" ? "des" : "asc";
        } else {
            dispatch(Actions.setChangeUserTypeSortType());
            userTyprSortType = userTyprSortType == "asc" ? "des" : "asc";
        }
        sortField = _sortField;
        var token = window.localStorage.getItem("token");
        getAllUsers(token);
    }

    if (isLoading) {
        return <_Loader />
    } else {
        if (users.length || window.localStorage.getItem("token")) {
            return (
                <div className="homeDiv">
                    <NavBar></NavBar>
                    <div className="homeMainDiv">
                        <AlertDismissible message="Error! User wasn't removed." show={showRemoveUserErrorWindow} action={Actions.setShowRemoveUserErrorWindow} />
                        <AlertDismissible message="Error! Link wasn't reactivated." show={showReactivateLinkErrorWindow} action={Actions.setShowReactivateLinkErrorWindow} />
                        <AlertDismissible message="Select user before action." show={showActionErrorWindow} action={Actions.setShowActionErrorWindow} />
                        <Confirmation />
                        {currentUser.isAdmin ? <>
                            <Button variant="primary" onClick={onOpenCreationWindow}>Create User</Button>
                            <Button variant="primary" className="btnMarginLeft" onClick={onEditUser}>Edit Selected User</Button>
                            <Button variant="primary" className="btnMarginLeft" onClick={onRemoveUser}>Remove Selected User</Button>
                        </> : <></>
                        }
                        {creationWindow}
                        <Table striped bordered hover className="usersTableWidth usersTableMargin">
                            <thead className="rowWidth">
                                <tr className="rowWidth">
                                    <th className="smallColumnWidth">User Selection</th>
                                    <th className="midleColumnWidth" id="userName" onClick={sort}>
                                        User Name
                                        {userNameSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </th>
                                    <th className="smallColumnWidth" id="userType" onClick={sort}>
                                        User Type
                                        {userTyprSortType == "asc" ?
                                            <img src={sortUpImage} className="sortImage" /> :
                                            <img src={sortDownImage} className="sortImage" />
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="rowWidth">
                                {usersTableBody}
                            </tbody>
                        </Table>
                        {paginationBasic}
                        {editWindow}
                    </div>
                </div>
            )
        } else {
            return <Redirect to="/login" />
        }
    }
}

const Users = connect(state => ({
    ...state
}))(_Users);

export default withRouter(Users)