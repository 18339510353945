export const Actions = {
    SET_AUTH_USERNAME: "SET_AUTH_USERNAME",
    SET_AUTH_PWD: "SET_AUTH_PWD",
    SET_CURRENT_USER: "SET_CURRENT_USER",
    SET_IS_SUCCESS_AUTH: "SET_IS_SUCCESS_AUTH",
    SET_AUTH_CONFIRM_PWD: "SET_AUTH_CONFIRM_PWD",
    SET_ACTIVE_MENU_OPTION: "SET_ACTIVE_MENU_OPTION",
    SET_SHOW_NEW_PWD_WINDOW: "SET_SHOW_NEW_PWD_WINDOW",
    SET_IS_SUCCESS_PWD_CHANGE: "SET_IS_SUCCESS_PWD_CHANGE",
    SET_NEW_PWD: "SET_NEW_PWD",
    SET_NEW_CONFIRM_PWD: "SET_NEW_CONFIRM_PWD",
    SET_OLD_PWD: "SET_OLD_PWD",
    SET_FOLDERS: "SET_FOLDERS",
    SET_FOLDERS_NAMES: "SET_FOLDERS_NAMES",
    SET_CREATION_PARENT_FOLDER_NAME: "SET_CREATION_PARENT_FOLDER_NAME",
    SET_UPLOAD_PARENT_FOLDER_NAME: "SET_UPLOAD_PARENT_FOLDER_NAME",
    SET_UPLOAD_FILE: "SET_UPLOAD_FILE",
    SET_UPLOAD_FILE_RECEIVER_EMAIL: "SET_UPLOAD_FILE_RECEIVER_EMAIL",
    SET_NEW_FOLDER_NAME: "SET_NEW_FOLDER_NAME",
    SET_ACTION: "SET_ACTION",
    SET_PARAM: "SET_PARAM",
    SET_USERS: "SET_USERS",
    SET_SELECTED_USER: "SET_SELECTED_USER",
    SET_SELECTED_LINK: "SET_SELECTED_LINK",
    SET_SHOW_EDIT_WINDOW: "SET_SHOW_EDIT_WINDOW",
    SET_IS_ADMIN: "SET_IS_ADMIN",
    SET_EDIT_USER_NAME: "SET_EDIT_USER_NAME",
    SET_EDIT_PWD: "SET_EDIT_PWD",
    SET_EDIT_IS_ADMIN: "SET_EDIT_IS_ADMIN",
    SET_IS_SUCCESS_EDIT: "SET_IS_SUCCESS_EDIT",
    SET_SELECTED_FOR_EDIT_USER: "SET_SELECTED_FOR_EDIT_USER",
    SET_SHOWN_FOLDERS: "SET_SHOWN_FOLDERS",
    SET_ACTIVE_PAGE_NUMBER: "SET_ACTIVE_PAGE_NUMBER",
    SET_PAGES: "SET_PAGES",
    SET_SHOW_CREATE_FOLDER_ERROR_WINDOW: "SET_SHOW_CREATE_FOLDER_ERROR_WINDOW",
    SET_SHOW_UPLOAD_FILE_ERROR_WINDOW: "SET_SHOW_UPLOAD_FILE_ERROR_WINDOW",
    SET_SHOW_GET_FILE_LINK_ERROR_WINDOW: "SET_SHOW_GET_FILE_LINK_ERROR_WINDOW",
    SET_SHOW_REMOVE_FOLDER_ERROR_WINDOW: "SET_SHOW_REMOVE_FOLDER_ERROR_WINDOW",
    SET_SHOW_REMOVE_FILE_ERROR_WINDOW: "SET_SHOW_REMOVE_FILE_ERROR_WINDOW",
    SET_SHOW_REMOVE_USER_ERROR_WINDOW: "SET_SHOW_REMOVE_USER_ERROR_WINDOW",
    SET_SHOW_REACTIVATE_LINK_ERROR_WINDOW: "SET_SHOW_REACTIVATE_LINK_ERROR_WINDOW",
    SET_HOME_COMPONENT_ERROR_WINDOW_MESSAGE: "SET_HOME_COMPONENT_ERROR_WINDOW_MESSAGE",
    SET_IS_LOADING: "SET_IS_LOADING",
    SET_IS_AUTH_LOADING: "SET_IS_AUTH_LOADING",
    SET_SHOW_CREATE_WINDOW: "SET_SHOW_CREATE_WINDOW",
    SET_CREATION_USER_NAME: "SET_EDIT_USER_NAME",
    SET_CREATION_PWD: "SET_EDIT_PWD",
    SET_CREATION_IS_ADMIN: "SET_EDIT_IS_ADMIN",
    SET_IS_SUCCESS_CREATION: "SET_IS_SUCCESS_CREATION",
    SET_CREATION_USER_NAME: "SET_CREATION_USER_NAME",
    SET_CREATION_PWD: "SET_CREATION_PWD",
    SET_CREATION_IS_ADMIN: "SET_CREATION_IS_ADMIN",
    SET_USER_FOR_ACTION: "SET_USER_FOR_ACTION",
    SET_SHOW_ACTION_ERROR_WINDOW: "SET_SHOW_ACTION_ERROR_WINDOW",
    SET_UPDATED_FOLDER_ID: "SET_UPDATED_FOLDER_ID",
    SET_SHOW_UPDATED_FOLDER_NAME: "SET_SHOW_NEW_FOLDER_NAME",
    SET_SHOW_NEW_FOLDER_NAME_WINDOW: "SET_SHOW_NEW_FOLDER_NAME_WINDOW",
    SET_UPDATED_FOLDER: "SET_UPDATED_FOLDER",
    SET_SHOW_UPDATE_FOLDER_NAME_ERROR_WINDOW: "SET_SHOW_UPDATE_FOLDER_NAME_ERROR_WINDOW",
    SET_SHOW_RECEIVERS_WINDOW: "SET_SHOW_RECEIVERS_WINDOW",
    SET_SHOW_RECEIVERS_ERROR_WINDOW: "SET_SHOW_RECEIVERS_ERROR_WINDOW",
    SET_ADD_RECEIVER: "SET_ADD_RECEIVER",
    SET_EDIT_RECEIVER: "SET_EDIT_RECEIVER",
    SET_EDIT_RECEIVER_FILE_ID: "SET_EDIT_RECEIVER_FILE_ID",
    SET_SHOW_HOME_SUCCESS_WINDOW: "SET_SHOW_HOME_SUCCESS_WINDOW",
    SET_CHANGE_SORT_TYPE: "SET_CHANGE_SORT_TYPE",
    SET_CHANGE_FILE_NAME_SORT_TYPE: "SET_CHANGE_FILE_NAME_SORT_TYPE",
    SET_CHANGE_RECIPIENT_SORT_TYPE: "SET_CHANGE_RECIPIENT_SORT_TYPE",
    SET_CHANGE_DATE_SENT_SORT_TYPE: "SET_CHANGE_DATE_SENT_SORT_TYPE",
    SET_CHANGE_USER_NAME_SORT_TYPE: "SET_CHANGE_USER_NAME_SORT_TYPE",
    SET_CHANGE_USER_TYPE_SORT_TYPE: "SET_CHANGE_USER_TYPE_SORT_TYPE",
    SET_CHANGE_USERS_SORT_FIELD: "SET_CHANGE_USERS_SORT_FIELD",
    SET_CHANGE_HISTORY_SORT_FIELD_AND_TYPE: "SET_CHANGE_HISTORY_SORT_FIELD_AND_TYPE",
    SET_CHANGE_HISTORY_USER_NAME_FILTER_TYPE: "SET_CHANGE_HISTORY_USER_NAME_FILTER_TYPE",
    SET_CHANGE_HISTORY_FILTER_CRITERIA_VALUE: "SET_CHANGE_HISTORY_FILTER_CRITERIA_VALUE",
    SET_CHANGE_HISTORY_SELECTED_SORT_FILED: "SET_CHANGE_HISTORY_SELECTED_SORT_FILED",
    SET_CHANGE_HISTORY_SELECTED_SORT_TYPE: "SET_CHANGE_HISTORY_SELECTED_SORT_TYPE",
    SET_CHANGE_HISTORY_SELECTED_FILTER_FIELD: "SET_CHANGE_HISTORY_SELECTED_FILTER_FIELD",
    SET_UPLOAD_PROGRESS_BAR_STAUTS: "SET_UPLOAD_PROGRESS_BAR_STAUTS",
    SET_SHOW_UPLOAD_PROGRESS: "SET_SHOW_UPLOAD_PROGRESS"
}